import { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FileInput,
  Paper,
  Title,
  Group,
  TextInput,
  Divider,
  SimpleGrid,
  Image,
  Checkbox,
  Table,
} from "@mantine/core";
import { fetcher } from "../../../../services/fetcher";
import {
  errorNotifications,
  successNotifications,
} from "../../../../utils/handleNotifications";
import CoverPricesTable from "../../../../common/components/Table/CoverpricesTable";
import classes from "./BulkIssuance.module.css";

const endpoint = "issuance/upload/";

const BulkIssuance = ({ data, operator, selectedCover }) => {
  const [opened, setOpened] = useState(false);
  const [formData, setFormData] = useState(null);
  const [details, setDetails] = useState(null);
  const [csvPreview, setCsvPreview] = useState([]);
  const { handleSubmit, control } = useForm({
    defaultValues: {
      csv_file: null,
      is_operator: operator ? true : false,
      is_client: operator ? false : true,
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const coverPricingRef = useRef(null);
  const summaryRef = useRef(null);

  const pricingEndpoint = `cover-api/${data.cover_type}/${data.id}/pricing`;

  useEffect(() => {
    if (selectedCover) {
      coverPricingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedCover]);

  useEffect(() => {
    if (opened) {
      summaryRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [opened]);

  if (!data) return null;

  const issueCoverMutation = async (travellers, createTrip = false) => {
    const url = operator
      ? `${endpoint}?id=${data.id}&cover_type=${data.cover_type}&operator=${
          operator.id
        }${createTrip ? "&create_trip" : ""}`
      : `${endpoint}?id=${data.id}&cover_type=${data.cover_type}${
          createTrip ? "&create_trip" : ""
        }`;

    try {
      const response = await fetcher(url, "POST", travellers, {
        "Content-Type": "multipart/form-data",
      });
      return response;
    } catch (error) {
      console.error(error);
      errorNotifications(error.response.data, error);
      throw error;
    }
  };

  const onSubmit = async (payload) => {
    setIsSubmitting(true);

    const newFormData = new FormData();

    const isClient = payload.is_client ? "True" : "False";
    const isOperator = payload.is_operator ? "True" : "False";

    console.log(payload);

    newFormData.append("csv_file", payload.csv_file);
    newFormData.append("Is_client", isClient);
    newFormData.append("is_operator", isOperator);

    for (const pair of newFormData.entries()) {
      console.log(pair[0], pair[1]);
    }

    try {
      const response = await issueCoverMutation(newFormData);
      setDetails(response);
      setFormData(newFormData);
      setOpened(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      await issueCoverMutation(formData, true);
      navigate("/issuance/policies");
      successNotifications("Success! Uploaded with trip creation.");
    } catch (error) {
      // Handle error if the final upload fails
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDownload = () => {
    const fileUrl = "/samplecsv/spreadsheet.csv";
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = "spreadsheet.csv";
    a.click();
  };

  const handleCsvPreview = (file) => {
    const reader = new FileReader();

    reader.onload = () => {
      const text = reader.result;
      const rows = text.split(/\r?\n/);
      const headers = rows[0].split(",");

      const dataRows = rows.slice(1);

      const parsedData = dataRows
        .filter((row) => row.trim() !== "")
        .map((row) => {
          const values = row.split(",");
          let rowObj = {};
          headers.forEach((header, index) => {
            rowObj[header.trim()] = values[index].trim();
          });
          return rowObj;
        });

      setCsvPreview(parsedData);
    };

    reader.readAsText(file);
  };

  return (
    <>
      <Paper my={"lg"} shadow="xl" className={classes.bulkIssuancePaper}>
        <CoverPricesTable
          pricingEndpoint={pricingEndpoint}
          selectedCover={selectedCover.title}
        />
      </Paper>

      <div ref={coverPricingRef}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper my={"lg"} p={"lg"}>
            <Title order={5} mb={"lg"}>
              Email Preferences
            </Title>

            <Group>
              <Controller
                name="is_client"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    radius="sm"
                    label="Customer"
                    onChange={field.onChange}
                    value={field.value}
                    defaultChecked={field.value === true}
                  />
                )}
              />

              <Controller
                name="is_operator"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    radius="sm"
                    label="Operator"
                    onChange={field.onChange}
                    value={field.value}
                    defaultChecked={field.value === true}
                    disabled={!operator}
                  />
                )}
              />
            </Group>
          </Paper>

          <Paper my={"lg"} p={"lg"}>
            <Group
              position="apart"
              align="center"
              style={{ justifyContent: "space-between" }}
            >
              <Title order={5}>Upload CSV File</Title>
              <Button
                variant="outline"
                onClick={handleDownload}
                leftSection={<Image src="/images/svg/download.svg" size={14} />}
              >
                CSV Sample File
              </Button>
            </Group>

            <Controller
              name="csv_file"
              control={control}
              rules={{ required: "Traveller CSV file required" }}
              render={({ field, fieldState: { error } }) => (
                <FileInput
                  {...field}
                  label=""
                  placeholder=""
                  accept=".csv"
                  classNames={{
                    input: classes.fileInput,
                    placeholder: classes.placeholder,
                  }}
                  error={error?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    handleCsvPreview(e); // Call CSV preview on file select
                  }}
                />
              )}
            />

            {/* Display CSV Preview */}
            {csvPreview.length > 0 && (
              <div className={classes.csvPreviewContainer}>
                <Table
                  my={"lg"}
                  highlightOnHover
                  className={classes.csvPreviewTable}
                >
                  <thead>
                    <tr>
                      {Object.keys(csvPreview[0]).map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {csvPreview.map((row, index) => (
                      <tr key={index}>
                        {Object.values(row).map((value, idx) => (
                          <td key={idx}>{value}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}

            <Group justify="center" mt="lg">
              <Button
                type="submit"
                variant="outline"
                my={"lg"}
                loading={isSubmitting}
                loaderProps={{ type: "dots" }}
              >
                Submit
              </Button>
            </Group>
          </Paper>
        </form>
      </div>

      {opened && (
        <Paper my={"lg"} p={"lg"} shadow="xl" ref={summaryRef}>
          <Title className={classes.coverpriceTitle}>Trip Summary</Title>
          <Divider mt="lg" mb="xl" />
          <SimpleGrid
            cols={{ base: 1, xs: 2, sm: 1, lg: 2 }}
            spacing={{ base: 10, xs: "xl", sm: "xl" }}
          >
            <TextInput
              label="No. of pax"
              value={details?.traveller_count || ""}
              readOnly
            />
            <TextInput
              label="Total Price"
              value={details?.total_price || ""}
              readOnly
            />
            <TextInput
              label="Wallet Amount"
              value={details?.user_wallet || ""}
              readOnly
            />
            {operator && (
              <>
                <TextInput
                  label="Commission Type"
                  value={details?.commission_type || ""}
                  readOnly
                />
                <TextInput
                  label="Commission Value"
                  value={details?.commission_value || ""}
                  readOnly
                />
                <TextInput
                  label="Commission"
                  value={details?.commission_price || ""}
                  readOnly
                />
              </>
            )}
          </SimpleGrid>
          <Group justify="center" mt={"xl"}>
            <Button
              onClick={handleConfirm}
              loading={isSubmitting}
              loaderProps={{ type: "dots" }}
            >
              Confirm
            </Button>
            <Button onClick={() => setOpened(false)} variant="outline">
              Cancel
            </Button>
          </Group>
        </Paper>
      )}
    </>
  );
};

export default BulkIssuance;
